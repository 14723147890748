import React from 'react'
import SEO from '../components/SEO'
import support from '../images/404/support-avatar.svg'

const NotFoundPage = ({ location }) => (
  <>
    <SEO title="404: Not found" location={location} doNotCanonicalize={true} />
    <div className="flex flex-col justify-center items-center bg-gp-grapefruit-10 px-6 pt-6 pb-12 text-center">
      <img
        src={support}
        className="w-32 md:w-64 mb-6"
        alt="Support member icon."
      />
      <h1 className="text-2xl md:text-3xl text-gp-violet mb-4 leading-snug">
        Sorry, it looks like we sent you to a broken page.
      </h1>
      <h2 className="text-xl md:text-2xl text-gp-grey-90 leading-snug">
        Let's get you back on a good path!
      </h2>
      <div className="gp-brand mt-6 mb-4">
        <a
          href="mailto:support@goodpath.com"
          className="btn-primary"
          target="_blank"
          rel="noreferrer"
        >
          Contact support
        </a>
      </div>
      <p className="text-gp-grey-80 leading-snu mb-12">
        Or enter&nbsp;
        <a href="mailto:support@goodpath.com" target="_blank" rel="noreferrer">
          support@goodpath.com
        </a>
        &nbsp;in your email provider.
      </p>
    </div>
  </>
)

export default NotFoundPage
